import React, {useRef, useState} from 'react'
import './App.css'
import PORT from './img/port.jpg'
import MOBILE_BGI from './img/background.jpeg'
import MOBILE_LOGO from './img/logo.png'
import {useMount, useRequest} from 'ahooks'
import {getUserInfo, postUserScore} from './api'
import {appId} from './constant'
import { Message } from '@arco-design/web-react';
import "@arco-design/web-react/dist/css/arco.css";


function toastRenderer(count: number) {
  if (count > 0) {
    return `你还有 ${count} 次机会`
  }
  return '游戏结束，祝君好运!'
}

const randomFn = () => new Array(6).fill(0).map(() => Math.ceil(Math.random() * 6))

const createDice = (num: any, i: any) => {
  const image = document.createElement('img')
  image.setAttribute('class', 'redpacket')
  image.id = 'redpacket' + i
  image.src = require('./img/' + num + '.jpg')
  return image
}

function App() {
  const [username, setUsername] = useState('')
  const [count, setCount] = useState(3)
  const [disabled, setDisabled] = useState(true)
  const toastDom = useRef<any>()
  const diceboxDom = useRef<any>()
  const placeholderDom = useRef<any>()

  // 点击骰子事件
  const doPlay = () => {
    if (count <= 0 || disabled) return

    setDisabled(true)
    placeholderDom.current.classList.add('hide')
    document.querySelectorAll('.redpacket')?.forEach(dom => dom.remove())
    const arr = randomFn()
    for (let i = 0; i < 6; i++) {
      diceboxDom.current.appendChild(createDice(arr[i], i))
    }
    toastDom.current.classList.remove('hide')
    run2PostUserScore(arr.join('|'))
  }

  // 上传用户成绩
  const {run: run2PostUserScore} = useRequest((score) => postUserScore({username, score}), {
    manual: true,
    onSuccess: (res) => {
      const { score, alias } = res
      setCount(count - 1)
      setDisabled(false)
      if (score === 0) {
        Message.normal('💪 再接再厉')
      } else {
        Message.normal(`🥳 恭喜！${alias}`)
      }
    },
    onError: (err) => {
      console.log('run2PostUserScore error === ', err)
    }
  })

  // 获取用户姓名和剩余次数
  const {run: run2GetUserInfo} = useRequest((code) => getUserInfo(code), {
    manual: true,
    onSuccess: ({data}) => {
      const {username, rest} = data
      setUsername(username)
      setCount(rest)
      if (rest > 0) {
        setDisabled(false)
      } else {
        setDisabled(true)
        toastDom.current.classList.remove('hide')
        placeholderDom.current.classList.add('hide')
      }
    },
    onError: (err) => {
      console.log('run2GetUserInfo error === ', err)
    }
  })

  //
  useMount(() => {
    ;(window as any).h5sdk.ready(() => {
      // @ts-ignore
      tt.requestAuthCode({
        appId,
        success: ({code}: any) => {
          run2GetUserInfo(code)
        },
        fail: (err: any) => {
          console.log('tt.requestAuthCode err === ', err)
        },
      })
    })
  })

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
    window.innerWidth < 500


  return (
    <div className="App">
      {
        isMobile ? (
          <>
            <div>
              <img className='container-logo' src={MOBILE_LOGO} />
              <img className="container-bgi" src={MOBILE_BGI}/>
            </div>

            <div className="main">
              <span className="count-toast hide" ref={toastDom}>{toastRenderer(count)}</span>
              <div className="bowl" id="dicebox" ref={diceboxDom} onClick={doPlay}>
                <img className="bowl-img" src={PORT}/>
                <span className="bowl-img_start" ref={placeholderDom}>轻触开始</span>
              </div>
            </div>
          </>
        ) : (
          <h1>请使用移动设备访问</h1>
        )
      }
    </div>
  )
}

export default App
