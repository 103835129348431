import { url } from "../constant";

export async function postUserScore({ score, username }: any) {
  return fetch(`${url}/users/score`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ score, username }),
  })
    .then((res) => res.json())
    .catch((err) => console.error("`postUsersScore` fail! ", err));
}
